import React from 'react';
import {Helmet} from 'react-helmet';
import {StaticImage} from 'gatsby-plugin-image';

import Logo from '../components/Logo';
import ContactForm from '../components/form/ContactForm';
import QuestionsAndAnswers from '../components/QuestionsAndAnswers';
import Quote from '../components/Quote';
import ClubOverview from '../components/cluboverview/ClubOverview';
import Block from '../components/Block';
import InstagramLink from '../components/InstagramLink';
import FacebookLink from '../components/FacebookLink';
import SocialMediaBlock from '../components/SocialMediaBlock';
import ImpressionBlock from '../components/ImpressionBlock';
import YouTubeVideo from '../components/YouTubeVideo';

const PlayersPage = () => {
    return (
        <>
            <Helmet title="Spieler:innen"/>
            <Logo title="Spieler:innen"/>

            <Block variant="primary" image={(
                <StaticImage src="../images/spieler/meyer.jpg" alt="Spieler:innen" aspectRatio={1}/>
            )}>
                <p className="text-white">Du hast Inlineskates, einen Eishockeyschläger und einen Ball und möchtest
                    gemeinsam mit deinen Freund:innen Hockey zocken? Dann bist du bei uns genau richtig. Beim
                    Skaterhockey geht‘s um Action, Spaß und Zusammenhalt. Skaterhockey ist vielseitig und besteht aus
                    Inlineskaten, präzisem Passspiel, zielgenauen Torschüssen und fairem Körpereinsatz. Wenn du gerne im
                    Tor stehst, bist du sicher gepanzert und fängst Ball für Ball mit der Fanghand oder wehrst ihn
                    akrobatisch mit Schläger, Schienen oder Stockhand ab.</p>

                <p className="text-white">Du möchtest auch mit dem Skaterhockey beginnen? Dann melde dich bei deinem
                    Verein ganz in deiner Nähe und frag, ob du ein Probetraining machen kannst. Die dortigen
                    Nachwuchstrainer:innen und Betreuer:innen helfen dir gerne bei all deinen Fragen und unterstützen
                    dich bei der Wahl von Ausrüstungsgegenständen.</p>
            </Block>

            <Block variant="light">
                <h2>Vom Parkplatz zum Nationalspieler</h2>
                <p>Schau Dir die Story und den beeindruckenden Weg der Brüder Florian und Maximilian Nies vom TV
                    Augsburg an…</p>
                <YouTubeVideo videoId="6pWkfEdcFpc"/>
            </Block>

            <Quote variant="primary"
                   image={<StaticImage src="../images/noebels_2.jpg" alt="Herzblut" width={400}/>}
                   title="Herzblut"
                   quote="Ich bin da wirklich mit Herzblut dabei. Ich werde Skaterhockey spielen, solange ich kann und meine Knochen es zulassen."
                   caption="Marcel Noebels, Eishockey-Nationalspieler"/>

            <Block variant="light">
                <h2>Finde deinen Verein</h2>
                <p>Du möchtest auch mit dem Skaterhockey beginnen? Dann melde dich bei deinem Verein ganz in deiner Nähe
                    und frag, ob du ein Probetraining machen kannst. Die dortigen Nachwuchstrainer:innen und
                    Betreuer:innen helfen dir gerne bei all deinen Fragen und unterstützen dich bei der Wahl von
                    Ausrüstungsgegenständen.</p>
                <ClubOverview/>
            </Block>

            <Block>
                <h2 id="contact">Kontakt</h2>
                <p>Du hast Interesse an unserem Sport und möchtest mehr erfahren? Sprich uns
                    bei <FacebookLink/> oder <InstagramLink/> an oder nutze das Kontaktformular!</p>
                <ContactForm topic="players"/>
            </Block>

            <Block variant="light">
                <h2>Skaterhockey FAQ</h2>
                <p>Jede Menge nützliche Infos rund ums Skaterhockey</p>
                <QuestionsAndAnswers questions={[{
                    question: 'Was brauche ich?',
                    answer: 'Inlineskates und Schutzausrüstung wie beim Eishockey.'
                }, {
                    question: 'Wo bekomme ich das her?',
                    answer: 'Spezielle Onlineshops bieten Einsteiger-Ausrüstungspakete. Im Fachhandel vor Ort kannst du sie anprobieren. Gebrauchte Ausrüstung gibt es bei den Clubs oder bei Onlineauktionshäusern.'
                }, {
                    question: 'Welche Altersklassen gibt es?',
                    answer: 'Es gibt die fünf Altersklassen U10 (zehn Jahre oder jünger), U13, U16, U19 und Herren/Damen.'
                }, {
                    question: 'Kann ich den Sport auch als Mädchen ausprobieren?',
                    answer: 'Selbstverständlich. Mädchen trainieren zusammen mit den Jungs in einem Team. Einige Clubs haben auch Damenmannschaften im Trainings- oder Spielbetrieb.'
                }, {
                    question: 'Wann kann man Skaterhockey spielen?',
                    answer: 'Die Sportart kann das ganze Jahr über gespielt werden. Die meisten Vereine spielen in eigenen Hallen (Turnhallen, Skaterhockey-Hallen, vereinzelt Eisstadien) oder auch auf Freiplätzen (diese sind dann witterungsabhängig).'
                }, {
                    question: 'Ich spiele bereits Eishockey, das ist mir aber zu stressig.',
                    answer: 'Dann bist du im Skaterhockey genau richtig. Denn du bringst schon ideale Vorkenntnisse mit. Die Trainings- und Spielzeiten lassen sich außerdem hervorragend mit der Schule und deinen anderen Aktivitäten verbinden.'
                }, {
                    question: 'Wie oft muss ich trainieren?',
                    answer: 'Skaterhockey-Teams trainieren in der Regel zwei Mal pro Woche. Viele Vereine bieten zusätzlich auch Trockentrainingseinheiten an.'
                }, {
                    question: 'Was kann ich darüber hinaus noch selbst trainieren?',
                    answer: 'Inlineskaten kann man natürlich auf Gehwegen und Parkplätzen selbst trainieren. Kondition und Kraft kann man sich selbst durch Laufen und spezielle Übungen erarbeiten. Auch hier helfen deine Trainer:innen gerne weiter. Auf Youtube findest du spezielle Übungen.'
                }, {
                    question: 'Wann und wie oft finden Spiele statt?',
                    answer: 'Die Spiele finden in der Regel samstags oder sonntags statt. Die Saison beginnt im Frühjahr und endet im Herbst. Die Zahl der Ligaspiele variiert je nach Ligastärke.'
                }, {
                    question: 'Was kann man gewinnen?',
                    answer: 'Skaterhockey ist ein Teamsport. Gemeinsam mit deinem Team kämpfst du um Tore und Siege und letztlich auch um die Meisterschaft in deiner Liga. Die besten Teams der Landesverbände nehmen im Nachwuchs an den Endrundenturnieren um die deutschen Meisterschaften teil.'
                }]}/>
            </Block>

            <SocialMediaBlock/>

            <ImpressionBlock alt="Impressionen Spieler:innen ">
                <StaticImage src="../images/impressions/herren_tor_szene_6.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/svejda.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_tor_szene_2.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_kabine.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_angriff_3.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_zweikampf_5.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_torwart_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_angriff_4.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_trainer_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_angriff_5.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_bank_3.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_tor_szene_10.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/otten.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_bully_5.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_tor_szene_9.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_schuss_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
            </ImpressionBlock>
        </>
    );
};

export default PlayersPage
